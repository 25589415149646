<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/sign-in"></ion-back-button>
        </ion-buttons>
        <ion-title>Sign Up</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="logo-header"><ion-img src="/assets/logo.png"></ion-img></div>
      <div class="login-container">
        <h3>Join the iSUGEZT movement</h3>
        <p class="black">and share your experiences directly.</p>
        <ion-input
          style="margin-top: 1em"
          autocomplete="email"
          inputmode="email"
          type="email"
          v-model="formData.email"
          class="form-control"
          placeholder="email address"
        />
        <p class="black">
          Verified users with validated experiences will earn rewards and be
          able to participate in special promotions and contensts.
        </p>

        <ion-input
          required
          type="password"
          v-model="formData.password"
          class="form-control"
          placeholder="password"
        />

        <ion-input
          required
          type="text"
          v-model="formData.name"
          class="form-control"
          placeholder="Name"
        />

        <div class="privacy-policy">
          <ion-checkbox
            slot="start"
            @update:modelValue="formData.privacyAccepted = $event"
            :modelValue="formData.privacyAccepted"
          >
          </ion-checkbox>
          <ion-label>
            I agree to the
            <a
              @click.stop.prevent="
                openLink('https://isugezt.com/policies/terms-and-conditions/')
              "
              href="https://isugezt.com/policies/terms-and-conditions/"
              target="_blank"
              >Terms &amp; Conditions</a
            >
            and
            <a
              @click.stop.prevent="
                openLink('https://isugezt.com/policies/privacy-policy/')
              "
              href="https://isugezt.com/policies/privacy-policy/"
              target="_blank"
              >Privacy Policy</a
            >.</ion-label
          >
        </div>

        <ion-button
          :disabled="CheckForm"
          slot="end"
          style="margin-top: 3em"
          color="primary"
          size="large"
          @click="signUp"
          >Sign Up</ion-button
        >
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth";

import {
  IonPage,
  IonContent,
  IonButton,
  IonInput,
  IonImg,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonCheckbox,
  IonLabel,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { InAppBrowser } from "@ionic-native/in-app-browser"

export default defineComponent({
  name: "Signup",
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonInput,
    IonImg,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonCheckbox,
    IonLabel,
  },
  data() {
    return {
      formData: {
        email: "",
        password: "",
        name: "",
        privacyAccepted: false,
      },
    };
  },
  computed: {
    CheckForm() {
      return !this.formData.privacyAccepted;
    },
  },
  methods: {
    signUp() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(
          this.formData.email,
          this.formData.password
        )
        .then((user) => {
          user.user.updateProfile({
            displayName: this.formData.name,
          })
          this.$router.replace("/tabs/home");
        })
        .catch((e) => {
          alert("oops" + e.message);
        });
    },
    openLink(url) {
      InAppBrowser.create(url, "_system");
    }
  },
});
</script>
<style scoped>
.black {
  color: #000;
  --ion-color: #000;
  max-width: 90%;
  text-align: center;
}
.privacy-policy {
  margin-top: 1em;
  max-width: 90%;
  display: flex;
  align-items: center;
}
.privacy-policy ion-checkbox {
  width: 2em;
  margin-right: 1em;
}
</style>